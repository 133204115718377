import { defineStore } from "pinia";
import { supabase } from "../lib/supabaseClient.js";

async function clearSupabaseSession() {
  console.log("clearSupabaseSession running");
  // Supabase stores its auth token information under 'supabase.auth.token'

  // sign out from the current session only
  let response = await supabase.auth.signOut({ scope: "local" });
  console.log("signout locally only", response);
  localStorage.removeItem("supabase.auth.token");

  // If you've stored any other Supabase-related data in localStorage or sessionStorage, clear those as well
  // For example:
  // localStorage.removeItem('your_custom_supabase_data_key');
  // sessionStorage.removeItem('your_custom_supabase_data_key');

  console.log("Supabase session data cleared from local storage.");

  return true;
}

export const useUserStore = defineStore("user", {
  state: () => ({ count: 0, user: null, authUser: null }),
  getters: {
    doubleCount: (state) => state.count * 2,
  },
  actions: {
    increment() {
      this.count++;
    },
    // login with supabase via email link
    async signInWithEmail(mail) {
      console.log("signInWithEmail: ", mail);
      const { data, error } = await supabase.auth.signInWithOtp({
        email: mail,
        options: {
          // set this to false if you do not want the user to be automatically signed up
          shouldCreateUser: false,
          emailRedirectTo: "https://writer.seedberg.com/",
        },
      });
      if (error) {
        console.log(error);
        return false;
      }
      this.user = data;
      return data;
    },
    // verify email link
    async verifyEmailLink(tokenHash, link) {
      console.log("verifyEmailLink: ", tokenHash, link);
      const { data, error } = await supabase.auth.verifyOtp({
        token_hash: tokenHash,
        type: "email",
      });
      console.log("verifyEmailLink: ", data, error);
      if (error) {
        console.log(error);
        return false;
      }
      this.authUser = data.session.user;
      //   from supabase table "applicationwriter_profiles" get user based on authUser.id
      let response = await this.getUserDetails();
      if (response) {
        return true;
      } else {
        return false;
      }
    },
    // logout
    async logout(onlyThisBrowser) {
      let scope;

      if (onlyThisBrowser) {
        scope = { scope: "local" };
      } else {
        scope = { scope: "global" };
      }
      const { error } = await supabase.auth.signOut(scope);
      if (error) {
        console.log("error during logout", error);
        // delete clearSupabaseSession
        let response = await clearSupabaseSession();
        console.log("response logout error", response);
        return error;
      }
      this.user = null;
      this.authUser = null;
      return true;

      //
    },
    // set user based on session
    async setUser() {
      console.log("setUser");
      // first logout
      // let response = await this.logout();
      // console.log("logout response:", response);

      const { data } = await supabase.auth.getSession();
      if (data.session !== null) {
        console.log("data, ", data);
        this.authUser = data.session.user;
        if (!this.user) {
          let response = await this.getUserDetails(true);
          if (response) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }
      return false;
    },

    async getUserDetails(noTracking) {
      const { data: profileData, error: profileError } = await supabase
        .from("applicationwriter_profiles")
        .select("*")
        .eq("id", this.authUser.id)
        .single();
      console.log("profileData: ", profileData, profileError);
      if (profileError) {
        console.log(profileError);
        return false;
      }
      this.user = profileData;

      if (!noTracking) {
        console.log("set userId for matomo");
        // window._paq.push(["setUserId", this.user.id]);
      }

      return true;
    },
    // setSession for extension auth
    async setSession(session) {
      console.log("setSession", session);

      // clearSupabaseSession();

      const { data, error } = await supabase.auth.setSession({
        access_token: session.access_token,
        refresh_token: session.refresh_token,
      });
      if (error) {
        console.log("setSession error:", error);
        return false;
      }
      console.log("setSession data:", data);
      this.authUser = data.user;
      let response = await this.getUserDetails();
      if (response) {
        return true;
      } else {
        return false;
      }
    },
  },
});
