<template>
  <Suspense>
    <div class="flex justify-center p-4 pt-16 max-w-screen-xl items-center mx-auto">
      <FormComp class="w-full" msg="Welcome to Your Vue.js App" />
    </div>
    <!-- loading state via #fallback slot -->
    <template #fallback>
      <div class="flex justify-center p-4">
        <LoadingSpinner />
      </div>
    </template>
  </Suspense>
</template>

<script>
// @ is an alias to /src
import FormComp from '@/components/FormComp.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'HomeView',
  components: {
    FormComp,
    LoadingSpinner
  }
}
</script>
