<template>
  <div class="w-full mb-6">
    <h1
      class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl dark:text-white">
      Seedberg's - Digitalisierungs Planer</h1>
    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Der schnellste
      Weg,
      ein Digitlaisierungs-Projekt zu starten (Alpha Version)</p>

    <h1 v-if="sandbox">SANDBOX ON!</h1>


    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-3 rtl:space-x-reverse">
        <li class="inline-flex items-center">
          <span
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
            <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 20 20">
              <path
                d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Start
          </span>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="w-3 h-3 text-gray-400 mx-1 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
            <span class="ms-1 text-sm font-medium text-gray-700 md:ms-2 dark:text-gray-400">
              Projekte</span>
          </div>
        </li>
        <li aria-current="page">
          <div class="flex items-center">
            <svg class="w-3 h-3 text-gray-400 mx-1 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
            <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{{
      projectStore?.project?.name }}</span>
          </div>
        </li>
      </ol>
    </nav>


    <div class="mx-auto px-4 pt-12">
      <div class="pb-4">
        <label for="minmax-range"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Fortschritt</label>
        <input id="minmax-range" type="range" min="0" max="100" :value="(100 / stepLength) * step"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" disabled>
      </div>
      <!-- form prevent empty submit-->
      <form class="mb-6 mt-6">

        <!-- original -->

        <div class="max-w-5xl mx-auto px-4" v-if="step === stepLength + 1">
          <!-- display LoadingSpinner -->

          <div>
            <div class="flex justify-between items-center mb-6">
              <h2 class="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">Final einreichen?
              </h2>
            </div>

            <div class="flex items-center mb-4">
              <input v-model="confirmSignup" id="default-checkbox" type="checkbox" value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Alle Daten
                werden mit
                dem Klick eingereicht.</label>
            </div>

            <!-- a checkbox that let's the user confirm that they can not change the project anymore on compeltion -->


            <button @click.prevent="submitForm"
              class="inline-flex items-center px-6 py-3.5 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800 disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:cursor-not-allowed"
              :disabled="confirmSignup === false">
              Einreichen
            </button>

          </div>
        </div>

        <!-- automation -->
        <!-- loop through questions array  -->
        <template v-for="(question, index) in  questions" :key="question?.id">
          <!-- add margin between questions if it has a lable or co -->
          <div :id="question.id" v-if="step === question.stepNumber"
            :class="[((question.stepNumber === questions[index + 1]?.stepNumber) && (questions[index + 1]?.label || questions[index + 1]?.label2 || questions[index + 1]?.headline)) ? 'mb-8' : '']">
            <!-- textinput -->
            <div :class="[questions[index].stepNumber ? ((index - 1 >= 0) && (questions[index - 1].stepNumber)) : '',
      'max-w-3xl mx-auto px-4 space-y-4']"
              v-if="(step === question.stepNumber) && (question.questionType !== 'radio' && question.questionType !== 'none') && (question.questionType !== 'checkbox')"
              :index="index && loading !== true">
              <!-- <div v-for="question in questions"></div> -->
              <div
                :class="[index > 0 && questions[index].stepNumber ? ((index - 1 > 0) && (questions[index - 1].stepNumber)) : 'flex justify-between items-center mb-6', '']">
                <h2 v-if="question.headline"
                  :class="['text-lg lg:text-2xl font-bold', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">
                  {{
      question.headline }}
                </h2>
              </div>
              <label for="company-website"
                :class="['block text-sm font-medium leading-6', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">{{
      question.label }}</label>

              <!--  suggestions -->
              <div class="space-y-2" v-if="question.suggestionMode">
                <label class="block text-sm font-medium text-gray-900 dark:text-white" v-if="question.suggestionMode">
                  Vorschläge
                </label>
                <!-- loop through answerArray and prefill a input field with the answerarray child data -->
                <template v-for="( answerObj, index ) in   answerArray  " :key="index">


                  <div v-if="!answerObj.isHidden" :customKEy="index" class=" flex items-center p-4 space-x-3 bg-gray-100 rounded-lg
                  dark:bg-gray-700">
                    <div class="grid w-full gap-3 md:grid-cols-1">
                      <textarea type="text"
                        class="overflow-y-auto min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :placeholder="answerObj.suggestion" :disabled="true" />
                    </div>

                    <button type="button" @click="hideSuggestion(index)"
                      class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
                      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clip-rule="evenodd" />
                      </svg>
                      <span class="sr-only">Delete</span>
                    </button>

                    <!-- pasted -->
                    <button v-if="question.appendMode" type="button"
                      @click="addSuggestion(index, question.connectedQuestionId, true)"
                      class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
                      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 text-blue-700 dark:text-blue-700" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          d="M18.017 15.002h-1.5v-1.5a1 1 0 0 0-2 0v1.5h-1.5a1 1 0 0 0 0 2h1.5v1.5a1 1 0 1 0 2 0v-1.5h1.5a1 1 0 1 0 0-2Z" />
                        <path
                          d="m17.74 4.758-7.476 8.409a1 1 0 0 1-.718.335h-.029a1 1 0 0 1-.707-.293l-4-4a1 1 0 0 1 1.414-1.413l3.25 3.25L16.53 3.11a9.5 9.5 0 1 0-3.885 15.355 2.495 2.495 0 0 1 .373-4.963 2.5 2.5 0 0 1 5 0c.035 0 .068.01.1.01a9.43 9.43 0 0 0-.38-8.754h.002Z" />
                      </svg>
                      <span class="sr-only">Hinzufügen</span>
                    </button>

                    <button v-else type="button" @click="addSuggestion(index, question.connectedQuestionId)"
                      class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-blue-700 dark:text-blue-700">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                      </svg>

                      <span class="sr-only">Ersetzen</span></button>
                  </div>
                  <div v-if="answerArray.length - 1 === index">
                    <!-- Show tooltip on top -->


                    <p class="text-xs font-light text-gray-500 dark:text-gray-400">
                      Manchmal haut's bei meinen generierten Inhalten vielleicht nicht so hin – aber hey, ich bin 'ne
                      künstliche Intelligenz ohne Gefühle oder ohne coolen Tanzmoves 🕺. Aber kein Stress, Kollege! Du
                      kannst mir
                      helfen,
                      indem du meine Vorschläge nimmst, Fakten überprüfst und deine eigenen Inhalte hinzufügst.
                      Gemeinsam
                      rocken wir das! 🤖✨
                    </p>

                  </div>
                </template>
              </div>

              <!-- Spinner -->
              <div v-if="question.suggestionMode && answerArray.length < 1">
                <div role="status"
                  class="space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                  <div class="flex items-center justify-between">
                    <div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <div class="flex items-center justify-between pt-4">
                    <div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <div class="flex items-center justify-between pt-4">
                    <div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <div class="flex items-center justify-between pt-4">
                    <div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <div class="flex items-center justify-between pt-4">
                    <div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <span class="sr-only">Loading...</span>
                </div>

                <button disabled type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor" />
                  </svg>
                  Loading...
                </button>

              </div>

              <label for="company-website"
                :class="['block text-sm font-medium leading-6', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">{{
      question.label2 }}
              </label>
              <!-- linked bound question to specific question -->
              <div v-if="question.connectedQuestionId && question.questionType === 'textinput'"
                class="mt-2 flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{
      questions[getIdOflinkedQuestion(question.connectedQuestionId)].inputLabel }}</span>
                <input v-model="textAnswers[questions[getIdOflinkedQuestion(question.connectedQuestionId)].id]"
                  v-debounce:700="validateUserInput" @input="question.valid = null"
                  :questionId="questions[getIdOflinkedQuestion(question.connectedQuestionId)].connectedPromptId"
                  type="text" id="initialAudience"
                  class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  :placeholder="questions[getIdOflinkedQuestion(question.connectedQuestionId)].placeholder"
                  :class="[questions[getIdOflinkedQuestion(question.connectedQuestionId)].valid === null ? inputfieldClassActive : questions[getIdOflinkedQuestion(question.connectedQuestionId)].valid ? inputfieldClassSuccess : question.fieldDisabled ? inputfieldClassDisabled : inputfieldClassError]"
                  :disabled="question.fieldDisabled" />
              </div>
              <!-- normal input question -->
              <div v-else-if="question.textareaRows == null && question.questionType === 'textinput'"
                class="mt-2 flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{
      question.inputLabel }}</span>
                <input v-model="textAnswers[question.id]" @input="question.valid = null"
                  v-debounce:700="validateUserInput" :questionId="question.connectedPromptId" type="text"
                  id="initialAudience"
                  class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  :placeholder="question.placeholder"
                  :class="[question.valid === null ? inputfieldClassActive : question.valid ? inputfieldClassSuccess : inputfieldClassError]" />
              </div>
              <div v-else-if="question.textareaRows && question.questionType === 'textinput'"
                class="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <label for="comment" class="sr-only">Your comment</label>
                <textarea v-model="textAnswers[question.id]" @input="question.valid = null" id="comment"
                  :rows="question.textareaRows"
                  class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                  :placeholder="question.placeholder || 'Beantworte die Frage...'" required></textarea>
              </div>
              <p v-if="question.reason !== '' && question.reason !== undefined" id="outlined_success_help"
                class="mt-2 text-xs text-amber-600 dark:text-amber-400"><span class="font-medium">Could you clarify
                  that?
                </span>
                {{
      question.reason }}</p>

            </div>
            <!-- checklist -->
            <!-- class if only checklist use max-w-5xl mx-auto px-4 -->
            <div :class="[
      questions[index].stepNumber ? (index - 1 >= 0 && questions[index - 1].stepNumber) : '',
      questions[index].stepNumber ? questions[index].stepNumber === questions[index + 1]?.stepNumber : '',
      'max-w-3xl mx-auto px-4 space-y-4',
      'max-w-3xl mx-auto px-4 space-y-4'
    ]
      " v-if="step === question.stepNumber && question.questionType === 'checkbox'" :index="index && loading !== true">
              <div>
                <div class="flex justify-between items-center mb-6">
                  <h2
                    :class="['text-lg lg:text-2xl font-bold', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">
                    {{
      question.headline }}
                  </h2>
                </div>

                <label for="company-website"
                  :class="['block text-sm font-medium leading-6', question.valid !== false ? 'text-gray-900' : 'text-red-600']">{{
      question.label }}
                </label>

                <h3
                  :class="['mb-5 text-lg font-medium', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">
                  {{
      question.label2 }}</h3>
                <ul class="grid w-full gap-6 md:grid-cols-2 grid-auto-rows-min">
                  <li v-for="   (  option, optionIndex  )    in      selectedAnswers[question.id]  "
                    :key="option.id + '-' + option.id" class="">
                    <!-- add selectedAnswers[question.id] binding -->
                    <div @click="handleCheckboxClicked(question, option)">
                      <input v-model="selectedAnswers[question.id][optionIndex].checked"
                        v-bind:id="question.id + '-' + option.id" @input="question.valid = null" type="checkbox"
                        value="" class="hidden peer" :disabled="isDisabledCheckbox(question, option)">
                      <label :for="question.id + '-' + option.id"
                        class="inline-flex items-center justify-between w-full h-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <div class="block">
                          <div class="w-full text-lg font-semibold break-words">{{ option.name }}</div>
                          <div class="w-full text-sm break-words">{{ option.description
                            }}</div>
                        </div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
            <!-- header only -->
            <div
              :class="[questions[index].stepNumber ? ((index - 1 >= 0) && (questions[index - 1].stepNumber)) : '', 'max-w-3xl mx-auto px-4']"
              v-if="step === question.stepNumber && question.questionType === 'none'"
              :index="index && loading !== true">
              <!-- <div v-for="question in questions"></div> -->
              <div
                :class="[index > 0 && questions[index].stepNumber ? ((index - 1 > 0) && (questions[index - 1].stepNumber)) : 'flex justify-between items-center mb-6', '']">
                <h2 v-if="question.headline" class="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">{{
      question.headline }}
                </h2>
              </div>
              <label for="company-website"
                :class="['block text-sm font-medium leading-6 ', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">{{
      question.label }}</label>
            </div>
            <!-- radio -->
            <div
              :class="[questions[index].stepNumber ? ((index - 1 >= 0) && (questions[index - 1].stepNumber)) : '', 'max-w-3xl mx-auto px-4 space-y-4']"
              v-if="step === question.stepNumber && question.questionType === 'radio'"
              :index="index && loading !== true">
              <h3 class="mb-5 text-lg font-medium text-gray-900 dark:text-white">
                {{ question.headline }}</h3>
              <label for="company-website"
                :class="['block text-sm font-medium leading-6', question.valid !== false ? 'text-gray-900 dark:text-white' : 'text-red-600']">{{
      question.label }}</label>
              <ul
                class="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <label v-for=" (     option, index     ) in      question.answerOptions     " :key="index">
                  <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                    <div class="flex items-center pl-3">
                      <input
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        :id="question.id + '-' + option.label" type="radio" :value="option.label"
                        v-model="selectedAnswers[question.id]" @input="question.valid = null" />
                      <label :for="question.id + '-' + option.label"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ option.label
                        }}
                      </label>
                    </div>
                  </li>
                </label>
              </ul>
            </div>
          </div>
        </template>
      </form>
      <div id="questionArea"></div>
      <div>
        <button v-if="step > 1" @click="step--"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          zurück
        </button>
        <button v-if="(step < stepLength + 1) && loading === false && step <= stepLength" @click="nextStep(true)"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Speichern und weiter
        </button>
        <button v-else-if="step <= stepLength" disabled
          class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          loading...
        </button>
      </div>


    </div>
    <div v-if="ToastMessageDisplay" id="toast-bottom-right"
      class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow right-5 bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
      role="alert">
      <svg v-if="ToastMessageType === 'check'" class="w-5 h-5 text-blue-600 dark:text-blue-500" aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
      </svg>
      <!-- warning icon -->
      <svg v-if="ToastMessageType === 'warning'" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
      </svg>

      <div class="pl-4 text-sm font-normal">{{ ToastMessage }}</div>
    </div>

    <div id="popup-modal" tabindex="-1"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button v-if="modalDetails?.option_close" id="closeButton" type="button"
            class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <div class="p-4 md:p-5 text-center">
            <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{{ modalDetails?.headline }}</h3>
            <br>
            <p class="text-sm text-gray-500 dark:text-gray-400">{{ modalDetails?.content }}</p>

            <!-- add a button to visit /final -->
            <button @click="visitFinal()"
              class="mt-5 w-full px-4 py-2 text-sm font-medium text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Zum Ergebnis
            </button>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { vue3Debounce } from 'vue-debounce'

export default {
  directives: {
    debounce: vue3Debounce({ lock: true })
  }
}
</script>

<script setup>
import { onMounted, ref, watch, onUpdated } from 'vue'
import { Modal, initFlowbite } from 'flowbite'
import { supabase } from "../lib/supabaseClient.js";
import LoadingSpinner from './LoadingSpinner.vue'
import { useProjectsStore } from '../stores/projects'

// import router
import router from '../router/index.js'

const projectStore = useProjectsStore()
let sandbox = process.env.VUE_APP_ENV === 'offline' ? true : false;
let testQuestions = require("../lib/testdataQuestions.json");
let testAnswers = require("../lib/testdataAnswers.json");

// setting to suggest if typed in data is valid
let instantSuggestion = false;


const answer = ref('');
const answerArray = ref([]);
const query = ref('');
const ToastMessage = ref('');
const ToastMessageType = ref('check');
const ToastMessageDisplay = ref(false);
const initialAudience = ref({ content: '', valid: null, reason: '' });
const initialProblem = ref({ content: '', valid: null, reason: '' });
const inputfieldClassActive = ref('block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6')
const inputfieldClassSuccess = ref('block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-green-900 ring-1 ring-inset ring-green-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6')
const inputfieldClassError = ref('block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-amber-900 ring-1 ring-inset ring-amber-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6')
const inputfieldClassDisabled = ref(' bg-gray-100 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-grey-900 ring-grey-600 ring-inset placeholder:text-gray-400 cursor-not-allowed sm:text-sm sm:leading-6')
const step = ref(1);
const stepLength = ref(1);
const loading = ref(false);
const questions = ref([]);
const existingAnswers = ref([]);
const selectedAnswers = ref({});
const textAnswers = ref({});
const projectId = ref("");
const programId = ref("");
const answerChanged = ref(false);
// get url parameter step and set step ref
const urlParams = new URLSearchParams(window.location.search);
const urlStep = urlParams.get('step');
const mode = urlParams.get('mode');
const confirmSignup = ref(false)
const customModal = ref(null)
const modalDetails = ref({})



// initialize components based on data attribute selectors
onMounted(async () => {
  initFlowbite();
  const $modalElement = document.querySelector('#popup-modal');
  const $closeButton = document.querySelector('#closeButton');

  const modalOptions = {
    backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
    closable: false
  }

  if ($modalElement) {
    console.log("modalElement")
    customModal.value = new Modal($modalElement, modalOptions);
    if ($closeButton) {
      $closeButton.addEventListener('click', () => customModal.value.hide());
    }
    console.log("modalElement: ", $modalElement)

    // programmatically show
    // if project status is locked display modal
    if (projectStore.project.status === "locked") {
      modalDetails.value.headline = "Projekt gesperrt";
      modalDetails.value.content = "Dieses Projekt ist bereits abgeschlossen und kann nicht mehr bearbeitet werden. Wenn du es trotzdem bearbeiten möchtest, kontaktiere uns.";

      customModal.value.show()
    }

  }
})

// get projectId from url parameter
projectId.value = urlParams.get('projectId');
// let projectId = "b8a92564-7560-431e-be3d-5db612416572";
console.log("projectId: ", projectId.value)
// we get the projectId from the project Store
let repsonse = await projectStore.setProjects(projectId.value);
console.log("repsonse: ", repsonse)
if (repsonse && projectStore.project.id && !projectId.value) {
  console.log("projectStore.project: ", projectStore.project)
  projectId.value = projectStore.project.id
  // push projectId to url
  window.history.pushState({}, null, `?projectId=${projectId.value}`);
}





programId.value = projectStore.project.connected_program
console.log("programId: ", programId.value)

// get questions from supabase database
async function getQuestions(connectedProgram) {
  // only main_question is false or null
  const { data, error } = await supabase
    .from('applicationWriter-Questions')
    .select('*')
    .eq('connected_program', connectedProgram)
    .not('main_question', 'is', true)
    .order('stepNumber', { ascending: true })
    .order('orderNumber', { ascending: true })
  if (error) {
    console.log("🚀 ~ file: FormComp.vue:1 ~ getQuestions ~ error", error)
  }
  else {
    console.log("🚀 ~ file: FormComp.vue:1 ~ getQuestions ~ data", data)
    return data;
  }
}
if (process.env.VUE_APP_ENV === 'offline') {
  questions.value = testQuestions
} else {
  questions.value = await getQuestions(programId.value);
}
console.log("questions: ", questions.value)



async function getAnswers(projectId) {
  if (process.env.VUE_APP_ENV === 'offline') {
    populateData(testAnswers)
  } else {
    const { data, error } = await supabase
      .from('applicationWriter-Answers')
      .select('*')
      .eq('connected_project', projectId)
      // order so the last answer will be filled last -> stays
      .order('created_at', { ascending: true })
      .then(async (response) => {
        await populateData(response.data)
        return true
      })
      .catch((error) => {
        console.error('Error retrieving answers:', error);
      });
  }

  async function populateData(response) {
    existingAnswers.value = response;
    console.log("existingAnswers: ", existingAnswers.value)


    // populate selectedAnswers.value[answer.questionId] first with empty question information
    questions.value.forEach((question) => {
      if (question.questionType === 'checkbox') {
        console.log("checker1: ", question.answerOptions)

        selectedAnswers.value[question.id] = question.answerOptions;
      }
      if (question.stepNumber > stepLength.value) {
        stepLength.value = question.stepNumber
      }

    });



    // Bind existing answers to their respective questions
    if (existingAnswers.value !== null) {
      existingAnswers.value.forEach((answer) => {

        // option in question.selectedOptions
        if ((questions.value[questions.value.findIndex(question => question.id === answer.questionId)].questionType === 'checkbox')) {
          if ("selectedOptions" in answer && answer.selectedOptions !== null) {
            console.log("answer.selectedOptions: ", answer.selectedOptions)
            selectedAnswers.value[answer.questionId] = [];
            selectedAnswers.value[answer.questionId] = answer.selectedOptions;
          }

        }

        if ((questions.value[questions.value.findIndex(question => question.id === answer.questionId)].questionType === 'radio')) {
          selectedAnswers.value[answer.questionId] = answer.selectedOptions;
        }


        if (questions.value[questions.value.findIndex(question => question.id === answer.questionId)].questionType === 'textinput') {
          console.log("answer.textAnswer: ", answer.answerText)
          textAnswers.value[answer.questionId] = answer.answerText;
        }
      });
    }
    return true;
  }
}



let response = await getAnswers(projectId.value);
console.log("done response")



// on form submit call this function
async function submitForm() {
  // do something here
  console.log('Form submitted')
  // update project and set status to "locked" in DB in projects store
  let response = await projectStore.updateProject(projectId.value, { status: "locked" })
  console.log("response, ", response)
  if (projectStore.project.status === "locked") {
    modalDetails.value.headline = "Projekt gesperrt";
    modalDetails.value.content = "Dieses Projekt ist bereits abgeschlossen und kann nicht mehr bearbeitet werden. Wenn du es trotzdem bearbeiten möchtest, kontaktiere uns.";

    customModal.value.show()
  }


}
async function invokeAssistant(query, questionId, mode, gptModel, placeholder) {
  console.log("invokeAssistant: ", query, questionId, mode, gptModel, placeholder)
  const { data, error } = await supabase.functions.invoke('application-writer', {
    body: { query: query, promptId: questionId, projectId: projectId.value, mode: mode ? mode : null, gptModel: gptModel ? gptModel : null, meta: placeholder ? placeholder : null }
  })
  console.log("🚀 ~ file: FormComp.vue:1 ~ callAssistant ~ data, error", data, error)
  if (error) {
    console.log("🚀 ~ file: FormComp.vue:1 ~ callAssistant ~ error", error);
    // stop execution if error
    return { data: null, error: error };
  }
  return { data, error };
}

async function callAssistant(query, questionId, gptModel, placeholder) {
  console.log("process.env.VUE_APP_ENV: ", process.env.VUE_APP_ENV)
  console.log("questionId: ", questionId)
  if (process.env.VUE_APP_ENV !== "offline") {
    let retry = 0;
    let data, error;

    await invokeAssistant(query, questionId, null, gptModel, placeholder)
      .then((response) => {
        // check if response is error if yes, return error
        if (response.error) {
          console.log("response.error: ", response.error)
          return response.error;
        }
        console.log("response.data: ", response.data)
        // add metadata to response

        // langchain get response from 1. obj in array key final_response - check if stringified json, if so parse
        if (response.data.length > 0 && response.data[0].final_response && typeof response.data[0].final_response === 'string') {
          response.data[0].final_response = JSON.parse(response.data[0].final_response);
          response.data[0].final_response.meta = response.data[0].meta;
          data = response.data[0].final_response;
        } else {
          data = response.data[0];
        }
        error = response.error;
      })
      .catch((error) => {
        // Handle the error here
        console.error("Error:", error);
        return { data: null, error: error };
      });

    // in case of error retry 3 times
    // while ((typeof data === 'string' || data === undefined || data === null) && retry < 3) {
    //   await invokeAssistant(query, questionId, null, gptModel)
    //     .then((response) => {
    //       data = response.data;
    //       error = response.error;
    //       retry++;
    //     })
    // }
    return data;
  }
  else {
    console.log(questionId)
    if (questionId === "PROMPT_V1_Q1_3_suggest" || questionId === "PROMPT_V1_Q1_4_suggest" || questionId === "PROMPT_V1_Q3_1_suggest" || questionId === "PROMPT_V1_Q3_2_suggest" || questionId === "823afea6-6926-4285-9086-eb7e7af413b3" || questionId === "57e3c04f-9269-4b93-8a2b-bed73dd87a30") {
      return { "audience": [{ "segment": "segment-name 1", "audience_size": "segment-size" }, { "segment": "segment-name 2", "audience_size": "segment-size" }, { "segment": "segment-name 3", "audience_size": "segment-size" }], "meta": { "mainValue": "segment" } };
    }
    else if (questionId === "PROMPT_V1_Q1_5_suggest" || questionId === "PROMPT_V1_Q1_6_suggest" || questionId === "PROMPT_V1_Q1_7_suggest" || questionId === "PROMPT_V1_Q2_1_suggest" || questionId === "da405462-357d-4f87-8579-6d428ddda01a" || questionId === "1aa3b2f3-03a5-4874-aeac-5a5cd5cee0d9") {
      return { "problems": [{ "problem": "problem-1" }, { "problem": "problem-2" }, { "problem": "problem-3" }] };
    }
    else if (questionId === "PROMPT_V1_Q5_1_suggest") {
      return { "audience": [{ "segment": "audience-name-1", "impacts": ["impact-1", "impact-2", "impact-3", "impact-4", "impact-5"] }, { "segment": "audience-name-2", "impacts": ["impact-1", "impact-2", "impact-3", "impact-4", "impact-5"] }, { "segment": "audience-name-3", "impacts": ["impact-1", "impact-2", "impact-3", "impact-4", "impact-5"] }] }
    }
    else if (questionId === "PROMPT_V1_Q6_1_suggest") {
      return { "solutions": [{ "category": "<category>", "product": "<product>", "description": "<description>" }, { "category": "<category>", "product": "<product>", "description": "<description>" }] }
    }
    else {
      console.log("questionId not defined in testing: ", questionId)
    }

  }
}

async function validateUserInput(query, e) {
  if (query.length < 3) {
    console.log('query too short')
    return
  } else {
    console.log('validateUserInput', query, e)
    console.log('query', query)
    console.log('e', e)
    const promptId = e.target.getAttribute('questionId')
    console.log('promptId', promptId)
    console.log("process.env.VUE_APP_ENV: ", process.env.VUE_APP_ENV)
    if (process.env.VUE_APP_ENV !== "offline" && instantSuggestion === true) {
      const { data, error } = await supabase.functions.invoke('application-writer', {
        body: { query: query, promptId: promptId }
      })
      console.log("🚀 ~ file: FormComp.vue:1 ~ validateUserInput ~ data, error", data, error)
      // tranistion data.content from string to object
      let dataObj = JSON.parse(data[0].final_response);
      console.log("dataObj", dataObj);
      console.log("dataObj.valid", dataObj.valid);

      // check which v-model was passed
      // adopt the code so it is dynamic (not hardcoded)
      if (e.target.getAttribute('id') === 'initialProblem') {
        initialProblem.value.reason = '';
        console.log('initialProblem')
        dataObj.valid ? initialProblem.value.valid = true : initialProblem.value.valid = false;
        if (dataObj.valid === false) {
          initialProblem.value.reason = dataObj.suggestion;
        }
      } else if (e.target.getAttribute('id') === 'initialAudience') {
        initialAudience.value.reason = '';
        console.log('initialAudience')
        dataObj.valid ? initialAudience.value.valid = true : initialAudience.value.valid = false;
        if (dataObj.valid === false) {
          initialAudience.value.reason = dataObj.suggestion;
        }
      }

    }
    // answer.value = data.content;
  }
}


function copyContent() {
  // do something here
  // copy answer.value to clipboard
  navigator.clipboard.writeText(answer.value).then(
    () => {
      /* clipboard successfully set */
      ToastMessage.value = 'Copied to clipboard'
      ToastMessageDisplay.value = true;
      console.log('Copy content')
      // hide toast after 3 seconds
      setTimeout(() => {
        ToastMessageDisplay.value = false;
      }, 3000);
    },
    () => {
      /* clipboard write failed */
      console.log('Copy failed')
    },
  );

  console.log('Copy content')
}

function addSuggestion(index, connectedQuestionId, appendMode) {
  console.log("addSuggestion")
  console.log("index: ", index)
  console.log("connectedQuestionId: ", connectedQuestionId)
  console.log("answerArray.value[index]: ", answerArray.value[index])
  let originalAnswer
  if (appendMode) {
    originalAnswer = (textAnswers.value[connectedQuestionId] || "") + " \n" + answerArray.value[index].original[answerArray.value[index].value];
    hideSuggestion(index)
  }
  else {
    originalAnswer = answerArray.value[index].original[answerArray.value[index].value];
    console.log(originalAnswer)
  }
  textAnswers.value[connectedQuestionId] = originalAnswer;

  // scroll to id questionArea
  let elmnt = document.getElementById("questionArea");
  elmnt.scrollIntoView();

}

function hideSuggestion(index) {
  answerArray.value[index].isHidden = true;
}

watch(step, async (newValue, oldValue) => {

  // if newValue is bigger than old value -> run nextStep()
  if (newValue > oldValue) {
    nextStep()
  } else {
    answer.value = '';
    answerArray.value = [];
  }

}, { deep: true })

if (urlStep) {
  step.value = parseInt(urlStep);
  nextStep(false)
}


async function nextStep(manuallyIncreased) {

  // first validate if all questions are answered#

  // loop through questions.value which has stepNumber === step.value and and check if either textAnswers.value[question.id] or selectedAnswers.value[question.id] is not null or undefined 
  let unansweredQuestions = null;
  for (const question of questions.value) {
    console.log("question.stepNumber: ", question.stepNumber)
    if (question.stepNumber === step.value) {
      let answerText = textAnswers.value[question.id];
      let storedInConnectedQuestion = false;
      // if connectedQuestionId is set, add answerText to textAnswers.value[connectedQuestionId]
      if ("connectedQuestionId" in question && question.connectedQuestionId !== null && question.connectedQuestionId in textAnswers.value) {
        answerText = textAnswers.value[question.connectedQuestionId];
        storedInConnectedQuestion = true;
      }
      let selectedOptions = selectedAnswers.value[question.id];

      // check if the questionType is none if so skip
      if (question.questionType === 'none') {
        console.log(question.id, " STOP because: question.questionType: ", question.questionType)
        continue;
      }
      console.log(question.id, " Answer: ", selectedOptions, answerText)
      if (!selectedOptions && !answerText) {
        // if not, show toast message and return
        unansweredQuestions = question.id;


        // mark question as invalid
        if (question.questionType === 'textinput') {
          if (storedInConnectedQuestion) {
            question.value[question.connectedQuestionId].valid = false;
          }
          else {
            question.valid = false;
          }
        }
        else if (question.questionType === 'checkbox') {
          question.valid = false;
        }
        else if (question.questionType === 'radio') {
          question.valid = false;
        }

        ToastMessage.value = 'Bitte beantworte alle Fragen.'
        ToastMessageType.value = 'warning';
        ToastMessageDisplay.value = true;
        // hide toast after 3 seconds
        setTimeout(() => {
          ToastMessageDisplay.value = false;
        }, 3000);
      }
    }
  }

  console.log("unansweredQuestions: ", unansweredQuestions)

  if (unansweredQuestions) {
    // scroll to element with ref = unansweredQuestions

    const element = document.getElementById(unansweredQuestions);
    console.log("element: ", element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    return;
  }

  // increase step by 1
  if (manuallyIncreased) {
    step.value = step.value + 1;
  }


  answer.value = '';
  answerArray.value = [];
  loading.value = true;
  // reset answer.value
  let loadingPrompts = false;
  let response = await questions.value.forEach(async (question, index) => {
    // console.log("question.stepNumber: ", question.stepNumber);
    if (question.stepNumber === step.value - 1) {
      console.log("question.stepNumber ONLY: ", question.stepNumber);
      console.log(question.id);
      //  reset question.valid
      question.valid = null;
      console.log(textAnswers.value[question.id]);
      let answerText = textAnswers.value[question.id];
      console.log("answerText: ", answerText);
      let storedInConnectedQuestion = false;
      // if connectedQuestionId is set, add answerText to textAnswers.value[connectedQuestionId]
      if ("connectedQuestionId" in question && question.connectedQuestionId !== null && question.connectedQuestionId in textAnswers.value) {
        console.log("question.connectedQuestionId: ", question.connectedQuestionId);
        answerText = textAnswers.value[question.connectedQuestionId];
        storedInConnectedQuestion = true;
        console.log("answerText: ", answerText);
      }
      console.log(textAnswers.value);
      console.log("selectedAnswers ONLY: ",);
      let selectedOptions = selectedAnswers.value[question.id];
      console.log("selectedOptions: ", selectedOptions);
      console.log("selectedOptions & answerText: ", selectedOptions, answerText);

      console.log(selectedAnswers.value);
      // get questionId
      if (process.env.VUE_APP_ENV !== "offline" && answerChanged.value === true && (answerText !== "" || selectedOptions !== "") && (answerText !== undefined || selectedOptions !== undefined) && (answerText !== null || selectedOptions !== null) && (answerText !== undefined || selectedOptions !== null) && (answerText !== null || selectedOptions !== undefined)) {


        const { data, error } = await supabase.from('applicationWriter-Answers').insert([
          {
            // user_id: user.value.id,
            questionId: storedInConnectedQuestion ? question.connectedQuestionId : question.id,
            answerText: answerText,
            selectedOptions: selectedOptions,
            connected_project: projectId.value,
          }
        ])

        console.log("answer data stored: ", data)
        console.log("answer data stored error: ", error)
        if (error) {
          ToastMessage.value = 'Error saving your data.'
          ToastMessageType.value = 'warning';
          ToastMessageDisplay.value = true;
          console.log('Error saving your data')
          // hide toast after 3 seconds
          setTimeout(() => {
            ToastMessageDisplay.value = false;
          }, 3000);
        }
        else {
          ToastMessage.value = 'Deine Daten wurden gespeichert.'
          ToastMessageType.value = 'check';
          ToastMessageDisplay.value = true;
          console.log('Your data was saved')
          // hide toast after 3 seconds
          setTimeout(() => {
            ToastMessageDisplay.value = false;
          }, 3000);

        }

      }
      else {
        console.log("NO DATA SAVED!!!")

      }

    }

    // check if serverSidePromptsInstructions are available 
    if (question.stepNumber === step.value) {
      if ("serverSidePromptsInstructions" in question && question.serverSidePromptsInstructions !== null) {
        loadingPrompts = true;
        console.log("question.serverSidePromptsInstructions: ", question.serverSidePromptsInstructions);
        // loop through serverSidePromptsInstructions and call callAssistant(query, questionId)
        question.serverSidePromptsInstructions.forEach(async (serverSidePrompt, index) => {
          console.log("serverSidePrompt: ", serverSidePrompt);
          console.log("serverSidePrompt.promptId: ", serverSidePrompt.promptId);

          // loop through serverSidePrompt.queries array and get queryId and queryName
          //
          console.log("serverSidePrompt.queries: ", serverSidePrompt.queries);
          // loop through a array that contains objects
          let query = "";
          let placeholders = {};
          for (const [key, value] of Object.entries(serverSidePrompt.queries)) {
            console.log("key: ", key);
            console.log("value: ", value);
            console.log("value.queryId: ", value.queryId);
            console.log("value.queryName: ", value.queryName);

            // Old version OpenAI 
            // // get query from textAnswers.value
            // query += value.queryName + ": '" + textAnswers.value[value.queryId] + "' ";
            // console.log("query: ", query)

            // new version langchain
            // pass query without placeholders and pass palcehodlers as meta
            placeholders[value.queryName] = textAnswers.value[value.queryId];

          }

          console.log("query: ", query)
          console.log("serverSidePrompt.gptModel: ", serverSidePrompt.gptModel)
          let response = await callAssistant(query, serverSidePrompt.promptId, serverSidePrompt.gptModel ? serverSidePrompt.gptModel : null, placeholders)
          // check if response is error if yes, return error
          if (!response) {
            console.log("response empty")
            // display error message to user with current question
            ToastMessage.value = `Es ist ein Fehler aufgetreten. Bitte versuche es erneut. Fehler-Code: #19201 (${serverSidePrompt.promptId})`;
            ToastMessageType.value = 'warning';
            ToastMessageDisplay.value = true;

            return
          }
          // working
          // not working
          console.log("response: ", response)
          answer.value += serverSidePrompt.preText;
          answer.value += arrayToHtml(response, 'PROMPT_V1_Q1_3_suggest');
          answer.value += "<br>";
          answer.value += serverSidePrompt.postTest;

          // check if last index and then return
          console.log("question.serverSidePromptsInstructions.length - 1 === index: ", question.serverSidePromptsInstructions.length - 1 === index)
          if (question.serverSidePromptsInstructions.length - 1 === index) {
            console.log("now return!")
            loading.value = false;
          }

        });
      }
    }
    // if last question and loading is true, set loading to false
    if (questions.value.length - 1 === index && loading.value === true && loadingPrompts === false) {
      loading.value = false;
    }

  });


}

function arrayToHtml(response, questionId) {
  let responseString = '';

  console.log("response: ", response)
  Object.keys(response).forEach((key, index) => {
    console.log("key: ", key)
    if (key !== "meta") {
      response[key].forEach((element, index) => {
        // Ensure element is an object before checking for properties
        if (typeof element === 'object') {
          let numb = index + 1; // Moved outside to avoid repetition

          if ("segment" in element && "audience_size" in element) {
            responseString += `- Zielgruppe: ${element.segment} (approx.: ${element.audience_size}) <br>`;
          } else if ("problem" in element) {
            responseString += `- Problem ${numb}: ${element.problem}\n\n`;
          } else if ("impacts" in element && "segment" in element) {
            responseString += `- Zielgruppe: ${element.segment}\n`;
            element.impacts.forEach((impact, idx) => {
              responseString += `--- Auswirkung ${idx + 1}: ${impact}\n`;
            });
            responseString += '\n'; // Moved outside the loop
          } else if ("category" in element && "product" in element && "description" in element) {
            responseString += `- Lösungsansatz ${numb}: \n-- Kategorie: ${element.category} -\n-- Beispiel: ${element.product} -\n--Beschreibung: ${element.description}\n\n`;
          } else {
            // If element doesn't match any of the above structures but is an object
            Object.keys(element).forEach(key => {
              responseString += `${key}: ${element[key]}\n\n`;
            });
          }
        } else {
          // If element is not an object, directly append it as a string
          responseString += `${element}<br>`;
        }
        console.log("answerArray.value")
        console.log(answerArray.value)
        console.log("response: ", response)
        console.log("response?.meta: ", response?.meta)
        // push the key value to answerArray.suggestion
        let suggestionText = "";
        let subelementMode = false;
        let parentElement = "";
        Object.keys(element).forEach((key, index) => {
          console.log("key + ': ' + element[key]", key + ': ' + element[key])
          suggestionText += key + ': ' + element[key];
          if (index !== Object.keys(element).length - 1) {
            suggestionText += ', ';
          }
          // // loop through element[response?.meta?.mainValueType]
          // //  check if this key is an array, if yes, push 
          if (Array.isArray(element[key])) {
            suggestionText = '';
            element[key].forEach(
              (subelement, index) => {
                console.log("subelement: ", subelement)
                console.log("element[key]: ", element[key])
                console.log("key: ", key)

                suggestionText = parentElement + ": " + subelement;
                let originalKey = response?.meta?.mainValue;
                answerArray.value.push({ "suggestion": suggestionText, "original": { [originalKey]: suggestionText }, "value": originalKey });
                subelementMode = true;
              }
            )
          } else {
            console.log("set parent element")
            parentElement = element[key];
            console.log("parentElement:", parentElement)

          }
          // answerArray.value.push({ "suggestion": suggestionText, "original": element, "value": response?.meta?.mainValue });

        });
        if (subelementMode === false) {
          answerArray.value.push({ "suggestion": suggestionText, "original": element, "value": response?.meta?.mainValue });
        }
      });
    }

  });
  console.log("responseString: ", responseString)
  return responseString;

}

// Watcher for the questions maxChecked property

let isDisabledCheckbox = (question, option) => {
  return selectedAnswers.value[question.id].filter(option => option.checked).length >= (question.maxChecked || 1000000) && !option.checked;
};

function handleCheckboxClicked(question, option) {
  if (isDisabledCheckbox(question, option)) {
    // Your function here
    console.log('Clicked on disabled');
    console.log('You can only select maxCount ideas')
    ToastMessage.value = 'Du kannst maximal ' + question.maxChecked + ' Antworten auswählen';
    ToastMessageType.value = 'warning';
    ToastMessageDisplay.value = true;
    // hide toast after 3 seconds
    setTimeout(() => {
      ToastMessageDisplay.value = false;
    }, 4000);
  }
}

//  Watch if any question was answered
watch(selectedAnswers, async (oldAnswer, newAnswer) => {
  console.log("answer content changed")
  answerChanged.value = true;
}, { deep: true }
);

//  Watch if any question was answered
watch(textAnswers, async (oldAnswer, newAnswer) => {
  console.log("answer content changed")
  answerChanged.value = true;
}, { deep: true });


function getIdOflinkedQuestion(connectedQuestionId) {
  // loop through questions and find the index of the question with the connectedQuestionId
  let questionIndex = questions.value.findIndex(question => question.id === connectedQuestionId);
  return questionIndex;
}

function visitFinal() {

  // disable modal
  customModal.value.hide()
  // visitFinal router to /final with same ?projectId=
  router.push({ name: 'final', query: { projectId: projectId.value } })

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
