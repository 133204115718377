<template>
  <Suspense>
    <div>
      <template v-if="!route.meta.headless">
        <HeaderComp />
      </template>
      <router-view class="w-full" />
    </div>
    <!-- loading state via #fallback slot -->
    <template #fallback>
      <p>Loading...</p>
      <LoadingSpinner />
    </template>
  </Suspense>
</template>

<script setup>
import { onMounted } from "vue";
import LoadingSpinner from './components/LoadingSpinner.vue'
import HeaderComp from './components/HeaderComp.vue'
import { supabase } from "./lib/supabaseClient.js";
import { useRoute } from 'vue-router'

const route = useRoute()

onMounted(async () => {
  supabase.auth.onAuthStateChange((event, session) => {
    console.log("🚀 ~ file: App.vue:30 ~ supabase.auth.onAuthStateChange ~ event, session:", event, session)
    if (event == 'SIGNED_IN') console.log('SIGNED_IN', session)
    if (event == 'SIGNED_OUT') console.log('SIGNED_OUT', session)
  })
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>