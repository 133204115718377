import { defineStore } from 'pinia'
import { supabase } from "../lib/supabaseClient.js";
import { useUserStore } from './user.js'


export const useProjectsStore = defineStore('projects', {
  state: () => ({ project: null, }),
  getters: {
    doubleCount: (state) => state.count * 2,
  },
  actions: {
    // set projects based on session
    async setProjects(projectId) {
        const userStore = useUserStore();
         console.log("userStore: ", userStore.user);
        console.log("setProjects: ", projectId);
        if(projectId){
        const { data, error } = await supabase.from('applicationWriter-Projects').select('*').eq('id', projectId).single()
        console.log("data: applicationWriter-Projects ", data);
        if (error) {
            console.log(error)
            return false
        }
        if(data){
        this.project = data
        return true
    }
} else {
    // get last project where connected_user is user.id
    const { data, error } = await supabase.from('applicationWriter-Projects').select('*').eq('connected_user', userStore.user.id).order('created_at', {ascending: false}).limit(1).single()
    console.log("data: applicationWriter-Projects ", data);
    if (error) {
            console.log(error)
            return false
        }
    if(data){
        this.project = data
        return true
    }
}

    },
    async updateProject(projectId, projectData) {
        console.log("updateProject: ", projectId, projectData);
        
const { data, error } = await supabase
  .from('applicationWriter-Projects')
  .update(projectData)
  .eq('id', projectId)
  .select()
          
        console.log("response updateProject: ", data, error)
        if (error) {
            console.log("error: ", error)
            return false
        }
        if(data[0]){
          console.log("updated project: ", data[0])
        this.project = data[0]
        return true
    }
    }
    
    
  },
})