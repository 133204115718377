import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { supabase } from "../lib/supabaseClient.js";
import { useUserStore } from "../stores/user.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/final",
    name: "final",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FinalView.vue"),
    meta: { requiresAuth: true },
  },
  // SingleAnswerView.vue
  {
    path: "/singleAnswer/:projectId/:questionId",
    name: "single",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SingleAnswerView.vue"),
    meta: { requiresAuth: true, headless: true },
  },
  {
    path: "/signin",
    name: "signin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SigninView.vue"),
    meta: { requiresAuth: false, headless: true },
  },
  {
    path: "/extensionAuth",
    name: "extensionAuth",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SigninExtensionView.vue"),
    meta: { requiresAuth: false, headless: true },
  },
  {
    path: "/extension-tutorial",
    name: "extension-tutorial",
    component: () => import("../views/ExtensionTutorial.vue"),
    meta: { requiresAuth: false, headless: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  // 1. check if user is logged in
  const userStore = useUserStore();

  const user = await userStore.setUser();
  console.log("🚀 ~ file: App.vue:23 ~ user", user);

  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !user && to.name !== "extensionAuth") {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: "/signin",
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }

  // if to signin and user is logged in, redirect to home
  if (to.name === "signin" && user) {
    return {
      path: "/",
    };
  }
});

export default router;
